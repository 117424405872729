import React, { useState, useEffect } from "react";
import jwtDecode from "jwt-decode";
import Swal from "sweetalert2";

import { API_URL_TES } from "../../config/index";
import { useNavigate, useParams } from "react-router-dom";
import { HiSave, HiArrowNarrowLeft } from "react-icons/hi";

import InputText from "../../components/Form2/InputText";

import useSWR from "swr";
import useSWRMutation from "swr/mutation";
import { sendRequestGet, sendRequestPostWithToken } from "../../swr";
import Loading from "../Loading";
import ErrorAccess from "../ErrorPage/ErrorAccess";
import TextArea from "../../components/Form2/TextArea";
import Select from "../../components/Form2/Select";

const FormCopyMasterSoal = () => {
  const { id } = useParams();

  const [namaSoal, setNamaSoal] = useState("");
  const [kodeSoal, setKodeSoal] = useState("");
  const [keterangan, setKeterangan] = useState("");
  const [petunjukSoal, setPetunjukSoal] = useState("");
  const [waktuPengerjaan, setWaktuPengerjaan] = useState(0);
  const [stMaster, setStMaster] = useState("N");

  const itemTipe = [
    {
      name: "Start",
      value: "Y",
    },
    {
      name: "Stop",
      value: "N",
    },
  ];

  const navigate = useNavigate();

  const { data, error, isLoading } = useSWR(
    `${API_URL_TES}/administrator/mastersoal/${id}`,
    sendRequestGet
  );

  const { trigger, isMutating } = useSWRMutation(
    `${API_URL_TES}/administrator/mastersoal`,
    sendRequestPostWithToken
  );

  useEffect(() => {
    // get refresh token
    checkToken();

    if (!isLoading && data) {
      setNamaSoal(data.namaSoal);
      setKodeSoal(data.kodeSoal);
      setKeterangan(data.keterangan);
      setPetunjukSoal(data.petunjukSoal);
      setWaktuPengerjaan(data.waktuPengerjaan);
      setStMaster(data.stMaster);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading]);

  const checkToken = () => {
    const token = localStorage.getItem("token");
    if (token) {
      const decoded = jwtDecode(token);

      const currentDate = new Date();
      if (decoded.exp * 1000 < currentDate.getTime()) {
        navigate("/login");
      }
    } else {
      navigate("/login");
    }
  };

  const simpan = async (e) => {
    e.preventDefault();
    try {
      const result = await trigger({
        namaSoal: namaSoal,
        kodeSoal: kodeSoal,
        keterangan: keterangan,
        petunjukSoal: petunjukSoal,
        waktuPengerjaan: waktuPengerjaan,
        stMaster: stMaster,
      });

      Swal.fire({
        icon: "success",
        title: "Sukses!",
        text: result.message,
        confirmButtonText: "Oke",
        willClose: () => {
          navigate(-1);
        },
      });
    } catch (e) {
      if (e.status > 400) {
        Swal.fire("Error!", e.info.message, "error");
      } else {
        Swal.fire("Error!", "Error, Silahkan ulangi kembali!", "error");
      }
    }
  };

  if (isLoading) return <Loading />;

  if (error) return <ErrorAccess />;

  return (
    <div className="mx-10 bg-orange-50 p-4 rounded-lg">
      <div>
        <h2 className="font-sans font-bold text-lg text-indigo-700">
          Copy Master Soal
        </h2>
        <p className="text-sm font-sans text-gray-400">
          Perubahan data master soal, isi informasi data dibawah ini.
        </p>
      </div>
      <form onSubmit={simpan}>
        <div className="mt-5 grid grid-cols-1 gap-x-4 gap-y-3 sm:grid-cols-6">
          <div className="sm:col-start-1 sm:col-span-4">
            <InputText
              label="Nama Soal"
              name="namaSoal"
              val={namaSoal}
              set={setNamaSoal}
              placeholder="Masukan nama soal"
            />
          </div>
          <div className="sm:col-start-1 sm:col-span-2">
            <InputText
              label="Kode Soal"
              name="kodeSoal"
              val={kodeSoal}
              set={setKodeSoal}
              placeholder="Masukkan kode soal"
            />
            <p className="mt-1 text-xs font-sans leading-6 text-gray-400">
              kode soal di buat manual sesuai pengkodean soal
            </p>
          </div>
          <div className="sm:col-span-1">
            <InputText
              label="Waktu Pengerjaan"
              name="waktuPengerjaan"
              val={waktuPengerjaan}
              set={setWaktuPengerjaan}
              placeholder="0"
            />
            <p className="mt-1 text-xs font-sans leading-6 text-gray-400">
              Waktu pengerjaan total second
            </p>
          </div>
          <div className="sm:col-span-1">
            <Select
              label="Status"
              name="stMaster"
              val={stMaster}
              set={setStMaster}
              item={itemTipe}
            />
            <p className="mt-1 text-xs font-sans leading-6 text-gray-400">
              Status master soal {stMaster}
            </p>
          </div>
          <div className="sm:col-start-1 sm:col-span-4">
            <TextArea
              label="Keterangan"
              name="keterangan"
              val={keterangan}
              set={setKeterangan}
              rows={3}
              placeholder="Masukan keterangan master soal"
            />
          </div>
          <div className="sm:col-start-1 sm:col-span-4">
            <TextArea
              label="Petunjuk Soal"
              name={petunjukSoal}
              val={petunjukSoal}
              set={setPetunjukSoal}
              rows={4}
              placeholder="Masukan petunjuk soal"
            />
          </div>
        </div>
        <div className="mt-10 flex items-center gap-x-2">
          <button
            type="button"
            className="text-gray-700 bg-[#fbbf24] hover:bg-[#fbbf24]/90 active::bg-[#fbbf24]/50  focus:outline-none focus:ring-[#b91c1c]/50 font-sans rounded-lg text-sm px-5 py-2 text-center inline-flex items-center shadow-md"
            onClick={() => navigate(-1)}
          >
            <span className="mr-2">
              <HiArrowNarrowLeft />
            </span>
            <span>Cancel</span>
          </button>
          <button
            type="submit"
            disabled={isMutating}
            className="text-white bg-[#3b5998] hover:bg-[#3b5998]/90 active::bg-[#3b5998]/50  focus:outline-none focus:ring-[#3b5998]/50 font-sans rounded-lg text-sm px-5 py-2 text-center inline-flex items-center shadow-md"
          >
            <span className="mr-2">
              <HiSave />
            </span>
            <span>Simpan Master Soal</span>
          </button>
        </div>
      </form>
    </div>
  );
};

export default FormCopyMasterSoal;
