import React, { useState, useEffect } from "react";
import jwtDecode from "jwt-decode";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

import { API_URL_TES } from "../../config/index";
import {
  HiOutlineRefresh,
  HiOutlinePencil,
  HiOutlineTrash,
  HiOutlinePlusSm,
  HiOutlineSearch,
  HiOutlineDuplicate,
} from "react-icons/hi";

import { formatDate } from "../../function/DateFormat";
import ErrorAccess from "../ErrorPage/ErrorAccess";

import useSWR from "swr";
import useSWRMutation from "swr/mutation";
import { sendRequestDelete, sendRequestGet } from "../../swr";
import Loading from "../Loading";
import Paging from "../../components/Paging";

const Table = () => {
  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const [itemPage, setItemPage] = useState(0);
  /* eslint-disable-next-line */
  const [limit, setLimit] = useState(10);
  const [field, setField] = useState("namaScoring");
  const [fieldQuery, setFieldQuery] = useState("namaScoring");
  const [keyword, setKeyword] = useState("");
  const [keywordQuery, setKeywordQuery] = useState("");

  const { trigger, isMutating } = useSWRMutation(
    `${API_URL_TES}/administrator/scoring`,
    sendRequestDelete
  );

  const { data, error, isLoading, mutate } = useSWR(
    `${API_URL_TES}/administrator/scoring?field=${fieldQuery}&keyword=${keywordQuery}&page=${page}&limit=${limit}`,
    sendRequestGet
  );

  // console.log(data);

  useEffect(() => {
    // get refresh token
    checkToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkToken = () => {
    const token = localStorage.getItem("token");
    if (token) {
      const decoded = jwtDecode(token);

      const currentDate = new Date();
      if (decoded.exp * 1000 < currentDate.getTime()) {
        navigate("/login");
      }
    } else {
      navigate("/login");
    }
  };

  // Fungsi untuk menghitung total kategori nilai dan rentang nilai
  function hitungTotalKategoriDenganRentang(kategoriNilai) {
    const kategoriTotals = {};
    const kategoriRentang = {};

    kategoriNilai.forEach((kategori) => {
      const namaKategori = kategori.namaKategori;
      const nilai = kategori.nilai;

      if (!kategoriTotals[namaKategori]) {
        kategoriTotals[namaKategori] = 0;
        kategoriRentang[namaKategori] = { min: Infinity, max: -Infinity };
      }

      kategoriTotals[namaKategori]++;
      kategoriRentang[namaKategori].min = Math.min(
        kategoriRentang[namaKategori].min,
        nilai
      );
      kategoriRentang[namaKategori].max = Math.max(
        kategoriRentang[namaKategori].max,
        nilai
      );
    });

    return Object.entries(kategoriTotals).map(([namaKategori, total]) => {
      const rentang = kategoriRentang[namaKategori];
      return `${namaKategori}: ${total} (${rentang.min} - ${rentang.max})`;
    });
  }

  const deleteData = async (id) => {
    Swal.fire({
      title: "Konfirmasi Hapus?",
      text: "Yakin ingin menghapus data!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, hapus data!",
      showLoaderOnConfirm: true,
      inputValidator: (value) => {
        return new Promise((resolve) => {
          if (this.validate(value)) {
            resolve();
          } else {
            resolve("check formatting...");
          }
        });
      },
      preConfirm: async (pno) => {
        if (pno) {
          try {
            const result = await trigger({ id: id });

            mutate();

            Swal.fire("Deleted!", result.message, "success");
          } catch (e) {
            Swal.fire("Deleted!", e.info.message, "error");
          }
        }
      },
    });
  };

  const changePage = ({ selected }) => {
    setItemPage(selected);
    setPage(selected + 1);
  };

  const searchData = (e) => {
    e.preventDefault();
    mutate();
    setPage(0);
    setItemPage(0);
    setKeywordQuery(keyword);
    setFieldQuery(field);
  };

  const resetPage = (e) => {
    e.preventDefault();
    mutate();
    setPage(0);
    setItemPage(0);
    setKeyword("");
    setKeywordQuery("");
    setField("namaScoring");
    setFieldQuery("namaScoring");
    setLimit(10);
  };

  if (isLoading || isMutating) return <Loading />;

  if (error) return <ErrorAccess />;

  return (
    <>
      <div className="px-2 mb-3">
        <div className="flex flex-row items-center">
          <div className="flex-1 mr-3">
            <form onSubmit={searchData}>
              <div className="flex">
                <label
                  htmlFor="search-dropdown"
                  className="mb-2 text-sm font-medium text-gray-900 sr-only"
                >
                  Filter
                </label>
                <select
                  id="countries"
                  className="text-sm font-sans text-gray-900 bg-gray-100 border border-gray-300 rounded-l-lg hover:bg-gray-200 focus:outline-none focus:ring-gray-300 focus:bg-gray-50 focus:border-blue-500"
                  value={field}
                  onChange={(e) => setField(e.target.value)}
                >
                  <option value="namaScoring">Nama Scoring</option>
                  <option value="deskripsi">Deskripsi</option>
                </select>

                <div className="relative w-full">
                  <input
                    type="search"
                    id="search-dropdown"
                    className="block p-2 w-full z-20 font-sans text-sm text-gray-900 bg-gray-100 rounded-r-lg border-l-gray-100 border-l-2 border border-gray-300 focus:ring-gray-300 focus:bg-gray-50 focus:border-blue-500"
                    placeholder="Ketik pencarian..."
                    value={keyword}
                    onChange={(e) => setKeyword(e.target.value)}
                  />
                  <button
                    type="submit"
                    className="absolute top-0 right-0 p-2.5 text-sm font-medium text-white bg-[#15803d] hover:bg-[#15803d]/90 active:bg-green-600 active:text-gray-300 rounded-r-lg border border-green-700 focus:ring-4 focus:outline-none focus:ring-blue-300"
                  >
                    <HiOutlineSearch size={18} />
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div className="flex-1 text-end">
            <button
              type="submit"
              className="text-black bg-white hover:bg-gray-300 active:bg-gray-100 active:text-gray-300 focus:ring-4 focus:outline-none focus:ring-[#F9FAFB]/50 rounded-lg text-sm px-5 py-2 text-center font-sans inline-flex items-center mr-2 shadow-md"
              onClick={resetPage}
            >
              <span className="mr-2">
                <HiOutlineRefresh size={18} />
              </span>
              <span>Reset</span>
            </button>
            <Link
              to="add"
              className="text-white bg-[#2563eb] hover:bg-[#2563eb]/90 active::bg-[#2563eb]/50  focus:outline-none focus:ring-[#2563eb]/50 rounded-lg font-sans text-sm px-5 py-2 text-center inline-flex items-center mr-2 shadow-md"
            >
              <span className="mr-2">
                <HiOutlinePlusSm size={18} />
              </span>
              <span>Tambah</span>
            </Link>
          </div>
        </div>
      </div>
      <div className="relative overflow-x-auto mb-3">
        <table className="w-full font-sans text-xs text-left text-gray-500 border-b">
          <thead className="border-b border-t text-gray-600 bg-white">
            <tr>
              <th scope="col" className="p-2 border-r">
                #
              </th>
              <th scope="col" className="px-2 py-3 border-r">
                NAMA SCORING
              </th>
              <th scope="col" className="px-2 py-3 border-r">
                MASTER SCORING
              </th>
              <th scope="col" className="px-2 py-3 border-r">
                DESKRIPSI
              </th>
              <th scope="col" className="px-2 py-3 border-r">
                KATEGORI NILAI
              </th>
              <th scope="col" className="px-2 py-3 border-r">
                TOT. NILAI
              </th>
              <th scope="col" className="px-2 py-3 border-r">
                CREATED AT
              </th>
              <th scope="col" className="px-2 py-3 border-r">
                UPDATED AT
              </th>

              <th scope="col" className="px-2 py-3">
                <span>AKSI</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {data ? (
              <>
                {data.docs.map((val, index) => {
                  const kategoriArray = hitungTotalKategoriDenganRentang(
                    val.kategoriNilai
                  );
                  return (
                    <tr
                      key={index}
                      className="bg-white border-b hover:bg-gray-50"
                    >
                      <td className="p-2 border-r">
                        <div className="flex items-center">
                          <input
                            id="checkbox-all-search"
                            type="checkbox"
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                          />
                          <label
                            htmlFor="checkbox-all-search"
                            className="sr-only"
                          >
                            checkbox
                          </label>
                        </div>
                      </td>
                      <td
                        className={
                          val.namaScoring === val.namaScoring.toUpperCase()
                            ? "px-2 py-2 text-gray-900 font-bold border-r"
                            : "px-2 py-2 text-gray-900 border-r"
                        }
                      >
                        {val.namaScoring}
                      </td>
                      <td className="px-2 py-2 text-gray-900 border-r">
                        {val.scoringId !== null
                          ? val.scoringId.namaScoring
                          : "-"}
                      </td>
                      <td className="px-2 py-2 text-gray-900 border-r">
                        {val.deskripsi}
                      </td>
                      <div className="px-2 py-2 text-gray-900 flex flex-col border-r">
                        {kategoriArray.map((item, index) => (
                          <span key={index}>{item}</span>
                        ))}
                      </div>
                      <td className="px-2 py-2 text-gray-900 border-r text-center">
                        <span className="px-2 py-0.5 ml-auto text-sm font-sans tracking-wide text-red-500 bg-red-50 rounded-full">
                          {val.kategoriNilai.length}
                        </span>
                      </td>
                      <td className="px-2 py-2 border-r">
                        {formatDate(val.createdAt)}
                      </td>
                      <td className="px-2 py-2 border-r">
                        {formatDate(val.updatedAt)}
                      </td>
                      <td className="px-2 py-2 text-center border-r">
                        <div className="inline-flex flex-row gap-2">
                          <Link
                            to={`copy/${val._id}`}
                            className="text-white px-2 py-2 bg-gray-400 hover:bg-gray-300 rounded-full"
                          >
                            <span>
                              <HiOutlineDuplicate size={14} />
                            </span>
                          </Link>
                          <Link
                            to={`${val._id}`}
                            className="text-white px-2 py-2 bg-blue-400 hover:bg-blue-300 rounded-full"
                          >
                            <span>
                              <HiOutlinePencil size={14} />
                            </span>
                          </Link>

                          <button
                            type="button"
                            onClick={() => deleteData(val._id)}
                            className="text-white px-2 py-2 bg-rose-400 hover:bg-rose-300 rounded-full"
                          >
                            <span>
                              <HiOutlineTrash size={14} />
                            </span>
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </>
            ) : (
              <tr className="bg-white border-b hover:bg-gray-50">
                <td colSpan="7" className="px-2 py-2 text-center">
                  Data tidak ditemukan
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {!isLoading && (
        <Paging
          totalDocs={data.totalDocs}
          totalPages={data.totalPages}
          page={itemPage}
          limit={limit}
          setLimit={setLimit}
          changePage={changePage}
        />
      )}
    </>
  );
};

export default Table;
