import React from "react";
import { Link, useParams } from "react-router-dom";
import Swal from "sweetalert2";

import useSWR from "swr";
import useSWRMutation from "swr/mutation";
import { sendRequestGet, sendRequestDelete } from "../../swr";
import Loading from "../Loading";

import { API_URL_TES } from "../../config/index";
import ErrorAccess from "../ErrorPage/ErrorAccess";
import {
  HiOutlineDuplicate,
  HiOutlinePencil,
  HiOutlinePlusSm,
  HiOutlineRefresh,
  HiOutlineTrash,
} from "react-icons/hi";

const TableSoal = () => {
  const { id } = useParams();

  const { data, error, isLoading, mutate } = useSWR(
    `${API_URL_TES}/administrator/mastersoal/${id}`,
    sendRequestGet
  );

  const { trigger, isMutating } = useSWRMutation(
    `${API_URL_TES}/administrator/mastersoal/question/${id}`,
    sendRequestDelete
  );

  const deleteData = async (idQuestion) => {
    Swal.fire({
      title: "Konfirmasi Hapus?",
      text: "Yakin ingin menghapus data!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, hapus data!",
      showLoaderOnConfirm: true,
      inputValidator: (value) => {
        return new Promise((resolve) => {
          if (this.validate(value)) {
            resolve();
          } else {
            resolve("check formatting...");
          }
        });
      },
      preConfirm: async (pno) => {
        if (pno) {
          try {
            const result = await trigger({ id: idQuestion });

            mutate();

            Swal.fire("Deleted!", result.message, "success");
          } catch (e) {
            Swal.fire("Deleted!", e.info.message, "error");
          }
        }
      },
    });
  };

  const resetPage = (e) => {
    e.preventDefault();
    mutate();
  };

  if (isLoading || isMutating) return <Loading />;

  if (error) return <ErrorAccess />;

  return (
    <>
      <div className="px-2 mb-3">
        <div className="flex flex-row items-center">
          <div className="flex-1 text-end">
            <button
              type="submit"
              className="text-black bg-white hover:bg-gray-300 active:bg-gray-100 active:text-gray-300 focus:ring-4 focus:outline-none focus:ring-[#F9FAFB]/50 rounded-lg text-sm px-5 py-2 text-center font-sans inline-flex items-center mr-2 shadow-md"
              onClick={resetPage}
            >
              <span className="mr-2">
                <HiOutlineRefresh size={18} />
              </span>
              <span>Reset</span>
            </button>
            <Link
              to="add"
              className="text-white bg-[#2563eb] hover:bg-[#2563eb]/90 active::bg-[#2563eb]/50  focus:outline-none focus:ring-[#2563eb]/50 rounded-lg font-sans text-sm px-5 py-2 text-center inline-flex items-center mr-2 shadow-md"
            >
              <span className="mr-2">
                <HiOutlinePlusSm size={18} />
              </span>
              <span>Question</span>
            </Link>
          </div>
        </div>
      </div>
      <div className="relative overflow-x-auto mb-3">
        <table className="w-full font-sans text-xs text-left text-gray-500 border-b">
          <thead className="border-b border-t text-gray-600 bg-white">
            <tr>
              <th scope="col" className="p-2 border-r">
                #
              </th>
              <th scope="col" className="px-2 py-3 border-r">
                NO
              </th>
              <th scope="col" className="px-2 py-3 border-r">
                PERTANYAAN
              </th>
              <th scope="col" className="px-2 py-3 border-r">
                TIPE
              </th>
              <th scope="col" className="px-2 py-3 border-r">
                SCORING
              </th>
              <th scope="col" className="px-2 py-3 border-r">
                KOLOM OPSI
              </th>
              <th scope="col" className="px-2 py-3 border-r">
                TOT. OPSI
              </th>
              <th scope="col" className="px-2 py-3">
                <span>AKSI</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {data.questions ? (
              <>
                {data.questions.map((val, index) => {
                  return (
                    <tr
                      key={index}
                      className="bg-white border-b hover:bg-gray-50"
                    >
                      <td className="p-2 border-r">
                        <div className="flex items-center">
                          <input
                            id="checkbox-all-search"
                            type="checkbox"
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                          />
                          <label
                            htmlFor="checkbox-all-search"
                            className="sr-only"
                          >
                            checkbox
                          </label>
                        </div>
                      </td>
                      <td className="px-2 py-2 text-black border-r">
                        {val.noQuestion}
                      </td>
                      <td className="px-2 py-2 text-gray-900 border-r">
                        {val.tpQuestion === "image" && (
                          <figure className="relative max-w-sm w-[120px] transition-all duration-300 cursor-pointer hover:shadow-xl hover:-translate-y-1">
                            <a
                              href={`${val.txtQuestion}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img
                                className="rounded-lg"
                                src={`${val.txtQuestion}`}
                                alt={val.txtQuestion}
                              />
                            </a>
                          </figure>
                        )}
                        {val.txtQuestion}
                      </td>

                      <td className="px-2 py-2 border-r text-center">
                        <span className="px-2 py-0.5 ml-auto text-sm font-sans tracking-wide text-red-500 bg-red-50 rounded">
                          {val.tpQuestion}
                        </span>
                      </td>
                      <td className="px-2 py-2 text-black border-r">
                        {val.scoringId !== null
                          ? val.scoringId.namaScoring
                          : "-"}
                      </td>
                      <td className="px-2 py-2 text-center border-r">
                        <span className="px-2 py-0.5 ml-auto text-sm font-sans tracking-wide text-orange-500 bg-green-50 rounded-full">
                          {val.columnOpsi}
                        </span>
                      </td>
                      <td className="px-2 py-2 text-center border-r">
                        <span className="px-2 py-0.5 ml-auto text-sm font-sans tracking-wide text-green-500 bg-green-50 rounded-full">
                          {val.questionOpsi.length}
                        </span>
                      </td>
                      <td className="px-2 py-2 text-center">
                        <div className="inline-flex flex-row gap-2">
                          <Link
                            to={`copy/${val._id}`}
                            className="text-white px-2 py-2 bg-gray-400 hover:bg-gray-300 rounded-full"
                          >
                            <span>
                              <HiOutlineDuplicate size={14} />
                            </span>
                          </Link>
                          <Link
                            to={`${val._id}`}
                            className="text-white px-2 py-2 bg-blue-400 hover:bg-blue-300 rounded-full"
                          >
                            <span>
                              <HiOutlinePencil size={14} />
                            </span>
                          </Link>

                          <button
                            type="button"
                            onClick={() => deleteData(val._id)}
                            className="text-white px-2 py-2 bg-rose-400 hover:bg-rose-300 rounded-full"
                          >
                            <span>
                              <HiOutlineTrash size={14} />
                            </span>
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </>
            ) : (
              <tr className="bg-white border-b hover:bg-gray-50">
                <td colSpan="7" className="px-2 py-2 text-center">
                  Data tidak ditemukan
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default TableSoal;
